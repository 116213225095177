<template>
  <!-- 小标题 -->
  <div class="newtitle">
    <div class="left">
      <div></div>
      <div>
        <font v-if="rules" style="color: #ee1c1c">*</font>
        {{ name }}
        <slot name="titleTips" v-if="titleTips">
          <!-- <el-tooltip effect="dark" placement="top-start">
            <div slot="content">
              {{ titleTips }}
            </div>
            <i class="iconfont iconwenhao"></i>
          </el-tooltip> -->
           <div class="tipBox">
               <i class="iconfont iconwenhao"></i>
               <div class="tipscontent">
                <span v-for="item in titleTipsArr" :key="item">{{item}}</span>
               </div>
            </div>
        </slot>
      </div>
    </div>
    <!-- 右侧插槽 -->
      <slot name="right">
      </slot>
  </div>
</template>
<script>
export default {
  name: 'titleCenter',
  props: {
    name: String,
    titleTips: String,
    rules: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    titleTipsArr () {
      return this.titleTips.split('//')
    }
  }
}
</script>
<style lang="scss" scoped>
.tipBox{

  display: flex;
    font-size: 14px;
    font-weight: 400;

}
.newtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  .left {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 10px;
    > div:nth-child(1) {
      width: 14px;
      height: 16px;
      background: #2862E7;
      position: relative;
      &::after {
        content: "";
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-bottom: 3px solid #fff;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    > div:nth-child(2) {
      font-size: 16px;
      font-weight: bold;
      color: #38373a;
      padding: 10px 10px 10px 9px;
      display: flex;
    }
  }
}
.iconwenhao {
  font-size: 18px;
  margin-left: 3px;
  color: rgba(255, 179, 77, 88);
}
</style>
